import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 676.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,676.000000) scale(0.100000,-0.100000)">


<path d="M7 6753 c-4 -3 -7 -1524 -7 -3380 l0 -3373 5400 0 5400 0 -2 3378 -3
3377 -5391 3 c-2965 1 -5394 -1 -5397 -5z m6020 -2303 c117 -31 250 -85 318
-129 49 -31 116 -87 95 -79 -11 4 -16 -1 -17 -16 0 -11 1 -15 4 -8 9 23 20 12
21 -24 1 -20 9 -50 17 -67 9 -19 11 -27 3 -19 -20 20 -32 13 -13 -8 10 -11 15
-20 10 -20 -4 0 -4 -6 1 -13 5 -7 2 -5 -7 4 -26 29 -139 112 -204 151 -84 51
-213 102 -325 128 -52 12 -100 23 -106 25 -12 3 -24 51 -24 93 0 24 1 25 58
18 31 -4 107 -20 169 -36z m-62 -171 c139 -35 323 -125 339 -165 3 -8 9 -13
13 -11 5 3 15 -2 23 -11 13 -14 13 -15 -1 -10 -10 4 -16 1 -16 -8 0 -8 4 -14
8 -14 5 0 7 -22 6 -50 -1 -27 -5 -50 -10 -50 -4 0 -6 3 -4 8 3 4 -1 13 -9 20
-8 7 -14 10 -14 7 0 -4 -19 9 -42 28 -94 76 -249 149 -380 177 -50 12 -57 16
-62 43 -13 63 -10 69 32 62 20 -4 73 -16 117 -26z m515 -53 c0 -2 -8 -10 -17
-17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z m-557 -107 c141 -36 279 -115
272 -155 -3 -17 1 -21 18 -18 12 2 17 2 10 -2 -8 -4 -9 -13 -2 -30 11 -31 11
-37 0 -30 -5 4 -7 -4 -3 -16 5 -23 5 -23 -16 -4 -12 10 -19 23 -15 29 3 6 1 7
-5 3 -7 -4 -29 6 -49 23 -56 43 -146 88 -227 112 -39 12 -74 26 -78 33 -12 17
-9 76 3 76 6 0 47 -9 92 -21z m-217 -115 c113 -24 175 -45 168 -56 -4 -6 -2
-8 4 -4 12 7 102 -33 102 -47 0 -4 5 -5 10 -2 6 3 10 -1 10 -10 0 -10 7 -15
18 -13 10 2 16 -2 14 -11 -2 -8 3 -11 10 -8 7 2 19 -2 26 -9 10 -12 10 -14 -2
-14 -8 0 -18 -4 -21 -10 -8 -13 38 -8 47 5 5 7 8 6 8 -1 0 -5 -7 -19 -15 -30
-17 -23 -20 -54 -4 -54 18 0 -11 -30 -30 -31 -10 0 -11 -2 -3 -6 6 -2 12 -9
12 -14 0 -6 -10 -5 -22 2 -22 12 -23 11 -5 -8 12 -13 16 -30 13 -48 -3 -15 -2
-24 2 -20 8 7 9 12 11 48 1 18 3 19 11 7 8 -11 9 -9 5 11 -4 17 -1 30 9 38 10
8 13 22 10 40 -4 17 0 32 9 39 12 11 15 10 13 -5 0 -10 4 -17 9 -16 6 1 11 -8
11 -19 0 -19 -1 -19 -18 2 -10 12 -18 16 -18 9 0 -7 8 -21 17 -31 16 -18 16
-18 -2 -12 -16 7 -18 3 -12 -34 5 -40 0 -48 -27 -43 -5 1 -6 -5 -3 -13 4 -9 2
-18 -3 -21 -6 -4 -10 -11 -10 -17 0 -6 6 -6 15 2 9 7 18 10 22 6 3 -3 0 -6 -7
-6 -9 0 -8 -4 4 -13 23 -17 17 -51 -9 -55 -15 -3 -17 -1 -9 8 9 9 4 19 -17 42
-15 17 -26 35 -23 39 2 4 -16 24 -40 45 -25 21 -43 41 -41 44 2 4 -1 7 -8 7
-23 1 -117 60 -110 68 5 4 3 5 -3 2 -7 -4 -47 2 -89 13 -96 24 -270 27 -363 5
-125 -30 -232 -104 -267 -187 -34 -83 -4 -225 58 -272 68 -51 163 -88 362
-141 170 -45 288 -87 335 -118 51 -34 61 -47 53 -61 -3 -5 -1 -6 4 -3 13 8 65
-42 57 -54 -3 -5 0 -9 6 -9 6 0 9 -4 6 -9 -3 -5 1 -12 10 -15 9 -4 14 -9 11
-13 -4 -3 -1 -13 6 -21 9 -13 9 -14 0 -8 -9 5 -11 2 -7 -8 3 -9 9 -16 13 -16
4 0 8 -6 8 -13 0 -7 3 -25 7 -41 4 -17 3 -25 -3 -22 -5 4 -12 2 -16 -4 -3 -5
0 -10 7 -10 10 0 14 -15 14 -51 0 -28 -3 -43 -6 -33 -6 17 -8 17 -19 -4 -6
-12 -8 -22 -4 -21 21 4 22 -3 3 -23 -11 -12 -20 -27 -20 -32 0 -6 5 -3 11 7
14 24 32 22 19 -3 -6 -11 -7 -20 -3 -20 4 -1 -3 -7 -17 -15 -14 -8 -19 -14
-12 -15 10 0 10 -4 0 -22 -7 -13 -12 -26 -13 -30 0 -4 -6 -13 -14 -20 -10 -9
-12 -9 -7 -1 5 9 2 11 -10 6 -13 -5 -15 -3 -9 7 6 9 4 11 -4 6 -9 -6 -9 -12 3
-25 20 -22 21 -38 2 -22 -12 10 -14 9 -10 -7 3 -10 -2 -24 -10 -31 -9 -8 -16
-10 -16 -6 0 3 -12 -7 -26 -24 -34 -41 -149 -95 -249 -116 -114 -25 -292 -17
-398 18 -90 29 -194 93 -241 148 -70 82 -116 197 -116 292 l0 47 100 0 100 0
0 -37 c0 -93 86 -206 192 -253 150 -66 379 -39 490 58 19 17 39 28 43 24 5 -4
5 -2 1 5 -3 6 2 27 12 45 22 40 43 136 31 144 -5 3 -9 14 -9 25 0 29 -42 91
-84 122 -47 37 -142 71 -296 108 -264 64 -391 118 -460 196 -55 62 -78 116
-91 210 -39 276 125 495 422 563 101 23 276 25 375 4z m-896 -859 l0 -855 -74
0 -74 0 -12 85 c-7 47 -15 85 -19 85 -4 0 -18 -12 -32 -26 -65 -70 -208 -142
-323 -163 -358 -65 -677 139 -761 486 -26 107 -17 281 18 386 71 207 234 358
443 411 38 10 104 16 171 16 166 0 269 -33 400 -127 l73 -52 0 304 0 305 95 0
95 0 0 -855z m-1787 605 c102 -244 173 -414 397 -945 111 -264 205 -488 207
-497 5 -16 -5 -18 -103 -18 l-109 0 -80 195 -80 195 -395 -2 -395 -3 -79 -192
-79 -193 -107 0 -108 0 28 68 c56 136 435 1065 532 1302 55 135 102 249 105
253 3 4 48 6 101 5 l96 -3 69 -165z m3214 85 c0 -8 -4 -12 -9 -9 -5 3 -6 10
-3 15 9 13 12 11 12 -6z m-44 -183 c-3 -7 -9 -10 -12 -5 -3 4 -10 11 -15 15
-4 4 1 6 12 5 12 -1 18 -6 15 -15z m-84 -151 c8 -14 7 -20 -4 -24 -9 -4 -12
-1 -8 9 3 7 0 14 -6 14 -6 0 -11 5 -11 10 0 17 19 11 29 -9z m51 -42 c0 -6 -4
-7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m-40 1 c0 -5
-5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m12 -32 c-7
-7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m-10 -354 c2 -209 0
-321 -7 -321 -6 0 -10 116 -10 328 0 180 3 325 7 321 4 -4 9 -152 10 -328z
m2010 70 c105 -35 171 -129 171 -244 0 -274 -368 -360 -489 -114 -102 210 97
434 318 358z m-2021 -431 c-12 -20 -14 -14 -5 12 4 9 9 14 11 11 3 -2 0 -13
-6 -23z m26 -15 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m20 -40
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-2 -68 c3 -5 -1 -10
-10 -10 -9 0 -13 5 -10 10 3 6 8 10 10 10 2 0 7 -4 10 -10z m41 -96 c1 -9 0
-13 -4 -10 -3 4 -7 14 -8 22 -1 9 0 13 4 10 3 -4 7 -14 8 -22z"/>
<path d="M5950 3900 c0 -5 -8 -7 -17 -3 -10 3 -15 3 -12 -1 4 -4 16 -8 27 -11
15 -2 19 0 15 11 -6 16 -13 19 -13 4z"/>
<path d="M6015 3829 c-11 -16 -1 -19 13 -3 7 8 8 14 3 14 -5 0 -13 -5 -16 -11z"/>
<path d="M5996 3691 c-3 -4 6 -19 19 -31 14 -13 25 -18 25 -12 0 6 -9 17 -20
25 -11 8 -17 17 -13 20 3 4 4 7 0 7 -3 0 -8 -4 -11 -9z"/>
<path d="M6070 3580 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M5860 3026 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M5947 2985 c3 -8 9 -12 14 -9 12 7 11 11 -5 17 -10 4 -13 1 -9 -8z"/>
<path d="M4095 3379 c-155 -20 -279 -108 -350 -249 -64 -126 -59 -329 12 -450
66 -113 179 -195 301 -221 78 -16 213 -6 282 20 112 43 221 153 262 267 32 89
36 224 9 311 -69 225 -274 353 -516 322z"/>
<path d="M2843 3608 c-6 -18 -73 -184 -148 -368 -76 -184 -140 -345 -142 -357
l-5 -23 317 0 c250 0 316 3 312 13 -30 77 -301 726 -311 744 l-12 24 -11 -33z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
